<template>
    <div :class="$style.footer_logo">
        <img src="../../../assets/images/logo/mastercard-card-ogo.png" alt="mastercard">
        <img src="../../../assets/images/logo/visa-logo.png" alt="visa">
        <img src="../../../assets/images/logo/rta.gif" alt="RTA">
    </div>
</template>

<script>
export default {
    name: "FooterLogo"
}
</script>

<style scoped module lang="scss">
.footer_logo {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    img {
        width: 100%;
        height: auto;
        max-width: 80px;
        margin: 0 5px;
        @media only screen and (max-width: 1265px) {
            max-width: 75px;
        }
        @media only screen and (max-width: 960px) {
            max-width: 70px;
        }
    }
}

</style>
