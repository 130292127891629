<template>
    <v-container fluid :class="$style.wrapper">
        <div :class="$style.logo">
            <router-link to="/">
                <v-img width="344" src="@/assets/images/logo/noodzly-logo.png"/>
            </router-link>
        </div>
        <h1 :class="$style.title">{{ title }}</h1>
        <div class="static-content">
            <slot></slot>
        </div>
        <footer-contact v-if="showFooterContact"/>
        <footer-logo v-if="showFooterLogo"/>
    </v-container>
</template>

<script>
import FooterContact from "@/components/app/common/FooterContact";
import FooterLogo from "@/components/app/common/FooterLogo";
export default {
    name: "StaticLayout",
    components: {FooterLogo, FooterContact},
    props: {
        title: {type: String},
        showFooterLogo: {type: Boolean, default: true},
        showFooterContact: {type: Boolean, default: true},
    },
}
</script>

<style scoped module lang="scss">
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    max-width: 1550px;
    padding-bottom: 87px;
}

.logo {
    width: 344px;

    @include media('md-and-down') {
        width: 146px;
    }
}

.title {
    margin-top: 58px;
    font-size: 85px !important;
    line-height: 103px;
    margin-bottom: 60px;
    font-family: 'Proxima Nova Lt', sans-serif;

    @include media('md-and-down') {
        margin-top: 20px;
        font-size: 55px !important;
        line-height: 60px;
        margin-bottom: 40px;
    }
    @include media('sm-and-down') {
        font-size: 45px !important;
        line-height: 50px;
        text-align: center;
        margin-bottom: 30px;
    }
}

</style>
