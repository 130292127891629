<template>
    <div :class="$style.footer_contact">
        NDZL TECHNOLOGIES LTD
        Louki Akrita, 21-23
        Floor 7, Flat/Office 46,
        1100, Nicosia, Cyprus
        VAT: 10435251P
        <a href="mailto:  hello@noodzly.com"> hello@noodzly.com</a>
    </div>
</template>

<script>
export default {
    name: "FooterContact"
}
</script>

<style scoped module lang="scss">
.footer_contact {
    text-align: center;
    margin-top: 30px;
    font-size: 1rem;
    @media only screen and (max-width: 1265px) {
        font-size: 0.8rem;
    }
    @media only screen and (max-width: 960px) {
        font-size: 0.6rem;
    }
}
</style>
